<template>
  <section id="iq-upcoming-movie">
    <b-container dir="rtl" fluid>
      <b-row>
        <b-col class="overflow-hidden" sm="12">
          <div
            class="iq-main-header d-flex align-items-center justify-content-end"
          >
            <h4 class="main-title">
              <div v-if="$i18n.locale == 'en'">My Contents</div>
              <div v-if="$i18n.locale == 'ku'">ناوەڕۆکەکانم</div>
              <div v-if="$i18n.locale == 'ar'">محتوياتي</div>
            </h4>
          </div>

          <div class="tabs" data-animation-in="fadeInUp" data-delay-in="1.2">
            <a
              v-if="hasItems('imageContents')"
              :class="{ active: activeTab === 'images' }"
              class="btn btn-hover viewMoreBtn text-center d-block"
              @click="activeTab = 'images'"
            >
              {{
                $i18n.locale == "en"
                  ? "Images"
                  : $i18n.locale == "ku"
                  ? "وێنە"
                  : "صورة"
              }}
            </a>
            <a
              v-if="hasItems('videoContents')"
              :class="{ active: activeTab === 'videos' }"
              class="btn btn-hover viewMoreBtn text-center d-block"
              @click="activeTab = 'videos'"
            >
              {{
                $i18n.locale == "en"
                  ? "Videos"
                  : $i18n.locale == "ku"
                  ? "ڤیدیۆ"
                  : "فيديو"
              }}
            </a>
            <a
              v-if="hasItems('audioContents')"
              :class="{ active: activeTab === 'audios' }"
              class="btn btn-hover viewMoreBtn text-center d-block"
              @click="activeTab = 'audios'"
            >
              {{
                $i18n.locale == "en"
                  ? "Audio"
                  : $i18n.locale == "ku"
                  ? "سروود"
                  : "أناشيد"
              }}
            </a>
          </div>

          <br />

          <div class="favorites-contens">
            <!-- video contents -->
            <div
              v-if="activeTab === 'videos'"
              v-show="activeTab === 'videos'"
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
            >
              <li
                v-for="(item, index) in videoContents"
                :key="index"
                class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"
              >
                <div class="block-images position-relative">
                  <div class="img-box">
                    <img
                      v-if="item.content_thumbnail != null"
                      :src="item.content_thumbnail"
                      alt=""
                      class="img-fluid2 img-fluid"
                    />
                    <img
                      v-else
                      alt=""
                      class="img-fluid2 img-fluid"
                      src="../../../assets/images/thumbnail.jpg"
                    />

                    <div class="block-description1">
                      <h6 class="iq-title">
                        <a
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                        >
                        </a>
                      </h6>

                      <div v-if="$i18n.locale == 'en'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                          ><i aria-hidden="true" class="fa fa-play mr-1">
                            Play Now
                          </i>
                        </a>
                      </div>

                      <div v-if="$i18n.locale == 'ku'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                          ><i aria-hidden="true" class="fa fa-play mr-1"> </i>
                          <span style="font-family: 'Speda'"
                            >ڤیدیۆکە لێبدە
                          </span>
                        </a>
                      </div>

                      <div v-if="$i18n.locale == 'ar'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                          ><i aria-hidden="true" class="fa fa-play mr-1"> </i>
                          <span style="font-family: 'Janna-LT'"
                            >شغل الفيديو
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- video -->

            <!-- audio contents -->
            <div
              v-if="activeTab === 'audios'"
              v-show="activeTab === 'audios'"
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
            >
              <li
                v-for="(item, index) in audioContents"
                :key="index"
                class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"
              >
                <div class="block-images position-relative">
                  <div class="img-box">
                    <img
                      v-if="item.content_thumbnail != null"
                      :src="item.content_thumbnail"
                      alt=""
                      class="img-fluid2 img-fluid"
                    />
                    <img
                      v-else
                      alt=""
                      class="img-fluid2 img-fluid"
                      src="../../../assets/images/thumbnail.jpg"
                    />

                    <div class="block-description1">
                      <h6 class="iq-title">
                        <a
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                        >
                        </a>
                      </h6>

                      <div v-if="$i18n.locale == 'en'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                          ><i aria-hidden="true" class="fa fa-play mr-1">
                            Play Now
                          </i>
                        </a>
                      </div>

                      <div v-if="$i18n.locale == 'ku'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                          ><i aria-hidden="true" class="fa fa-play mr-1"> </i>
                          <span
                            style="
                              font-family: 'Speda';
                              position: relative;
                              top: -10px;
                            "
                            >سروودەکە لێبدە
                          </span>
                        </a>
                      </div>

                      <div v-if="$i18n.locale == 'ar'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                          ><i aria-hidden="true" class="fa fa-play mr-1"> </i>
                          <span style="font-family: 'Janna-LT'">شغل </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- audio -->

            <!-- image contents -->
            <div
              v-if="activeTab === 'images'"
              v-show="activeTab === 'images'"
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
            >
              <li
                v-for="item in imageContents"
                v-show="item.content_url[$i18n.locale] != null"
                :key="item.id"
                class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"
              >
                <div class="block-images position-relative">
                  <div class="img-box">
                    <img
                      :src="item.content_url[$i18n.locale]"
                      alt=""
                      class="img-fluid2 img-fluid"
                    />

                    <div class="block-description1">
                      <h6 class="iq-title">
                        <a
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                        >
                        </a>
                      </h6>

                      <div v-if="$i18n.locale == 'en'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                          ><i aria-hidden="true" class="fa fa-play mr-1">
                            View
                          </i>
                        </a>
                      </div>
                      <div v-if="$i18n.locale == 'ku'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                        >
                          <i aria-hidden="true" class="fa fa-play mr-1"> </i>
                          <span style="font-family: 'Speda'"> بینین </span>
                        </a>
                      </div>
                      <div v-if="$i18n.locale == 'ar'" class="hover-buttons">
                        <a
                          class="btn btn-hover"
                          role="button"
                          @click="
                            gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                $route.params.categoryId
                            )
                          "
                        >
                          <i aria-hidden="true" class="fa fa-play mr-1"></i>
                          <span style="font-family: 'Janna-LT'">عرض </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- image -->
          </div>
          <!-- </Slick> -->
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import ApiService from "../../../services/api"

export default {
  name: "PopularShow",
  props: ["videoContents", "imageContents", "audioContents"],
  components: {},
  mounted() {
    if (this.videoContents.length > 0) {
      this.activeTab = "videos"
    } else if (this.imageContents.length > 0) {
      this.activeTab = "images"
    } else if (this.audioContents.length > 0) {
      this.activeTab = "audios"
    }
  },
  data() {
    return {
      activeTab: "videos",

      showImagePopup: false,
      imageContentPath: "",
      favOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        prevArrow:
          '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  methods: {
    hasItems(tab) {
      // Check if the given tab has items
      return this[tab].length > 0
    },

    gotoPage(link) {
      this.$router.push(link)
    },

    next() {
      this.$refs.dSlick.next()
    },
    prev() {
      this.$refs.dSlick.prev()
    },
    closeContent() {
      this.imageContentPath = ""
      this.showImagePopup = false
      //  this.imagePopup = false
    },
    openContent(id) {
      this.showImagePopup = true
      var self = this
      ApiService.openContent(id).then(response => {
        this.$store.commit("setPoints", response.data.user_points)
        self.imageContentPath = response.data.data.content_url.ku
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.main-title {
  font-size: 22px;
  align-items: right;
  // padding-right: 1240px;
  padding-top: 100px;
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.hover-buttons .btn {
  height: 50px;
  width: 130px;
}

.fa {
  font-size: 16px !important;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .fa {
    font-size: 12px !important;
    padding-top: 10px;
  }
  span {
    font-size: 12px !important;
  }
}

.block-description1 {
  position: absolute;
  left: 25px;
  bottom: 10px;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.tabs {
  display: flex;
}

button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

button.active {
  background: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  color: #f0f0f0;
}

.tab-content {
  margin-top: 20px;
}

.img-fluid2 {
  height: 200px !important;
  width: 100% !important;
}

.active {
  background: linear-gradient(to right, #f5ce62, #f2651a, #fa7199) !important;
}
</style>
