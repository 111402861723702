<template>
  <div>
    <div v-if="isLoading" class="text-center mt-5 pt-5">
      <div class="spinner-grow text-dark m-5 p-5 text-center" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <Videos
      v-else
      :audioContents="audioContents"
      :imageContents="imageContents"
      :videoContents="videoContents"
    />
  </div>
</template>

<script>
import { core } from "../../config/pluginInit"
import Videos from "./MyVideos/videos"
import ApiService from "../../services/api"

export default {
  name: "MovieCategory",
  data() {
    return {
      isLoading: true,
      contents: [],
      videoContents: [],
      imageContents: [],
      audioContents: []
    }
  },
  components: {
    Videos
  },
  methods: {
    myVideos() {
      ApiService.myContents(this.$route.params.categoryId)
        .then(response => {
          this.$store.commit("setPoints", response.data.user_points)
          this.contents = response.data.data
          this.videoContents = this.contents.filter(content => {
            return content.content_type === "Video"
          })
          this.imageContents = this.contents.filter(content => {
            return content.content_type === "Image"
          })
          this.audioContents = this.contents.filter(content => {
            return content.content_type === "Audio"
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted() {
    core.index()
    this.myVideos()
  }
}
</script>

<style>
.tab-nav {
  list-style-type: none;
  display: flex;
  margin-right: 1100px;
  margin-left: 100px;
  background-color: #829cdc;
  border: 1px solid #212529;
  border-radius: 40px;
}

.tab-nav li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: auto;
}

.tab-nav li.active {
  background-color: #ffffff;
  border-bottom: 2px solid #fafafa;
  color: #212529;
  border: 1px;
  border-radius: 40px;
  margin-right: auto;
}
</style>
